.container {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;
}

.menu {
  height: 100%;
  width: 20%;

  display: flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;

  position: fixed;
}

.menu_content {
  padding: 40px;
}

.title {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 8px;
  margin-top: 0;
}

.sub_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 8px;
  margin-top: 0;
}

.sec_title {
  font-size: 28.8px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 8px;
  margin-top: 0;
}

.page_title {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 8px;
  margin-top: 0;

  padding-left: 1em;
}

.page_content_title {
  font-size: 120px;
  font-weight: 300;
  /* line-height: 1.2; */
  margin-top: 0;
  /* margin-left: 16px; */

  font-family: "Roboto";
  font-style: italic;
}

.page_content_subtitle {
  font-size: 40px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 24px;

  font-family: "Roboto";
}

.page_content_content {
  font-family: "Inter";
  font-weight: 300;
  font-style: normal;
  line-height: 1.8em;
  font-size: 24px;
  text-transform: none;
  text-decoration: none;
  margin: 0 0 1em;
}

.page_content_container {
  margin-top: 48px;
  margin-bottom: 48px;
  width: 100%;
}

hr {
  background: rgba(127, 127, 127, 0.2);
  border: 0;
  height: 1px;
  display: block;

  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;

  margin-top: 16px;
  margin-bottom: 16px;
}

.section {
  margin-top: 40px;
  margin-bottom: 56px;
}

.clickable {
  color: rgba(0, 0, 0);
  cursor: pointer;
  /* filter: blur(1px); */
}

.clickable:hover {
  color: rgba(194, 194, 194, 1);
  filter: blur(1px);
}

.clickable.active {
  color: rgba(194, 194, 194, 1);
  filter: blur(1px);
}

.clickable_large {
  color: rgba(0, 0, 0);
  cursor: pointer;
  /* filter: blur(1px); */
}

.clickable_large:hover {
  color: rgba(194, 194, 194, 1);
  filter: blur(2px);
}

.clickable_large.active {
  color: rgba(194, 194, 194, 1);
  filter: blur(2px);
}

/* CONTENT */
.content {
  height: 100%;
  width: 80%;
  margin-left: 20%;

  overflow: auto;
  margin-right: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.moblie_content {
  overflow: auto;
  margin-right: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.gallery {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  /* margin: -8px; */
  padding: 8px;
}

.page_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  padding: 8px;
  padding-top: 40px;
  padding-left: 80px;
  padding-right: 80px;
  /* padding-bottom: 100px; */
  /* width: 90%; */
  /* width: 54%; */
}

.desktop_width {
  width: 54%;
}

.grid {
  width: 50%;
  aspect-ratio: 16/9;

  box-sizing: border-box;
  padding: 4px;
}

.grid.mobile {
  width: 100%;
}

.grid_content {
  background-color: gray;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

b {
  font-weight: 600;
}

.img_aspect {
  width: 100%;
  aspect-ratio: auto;
  height: auto;
}

.center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.img_margin {
  margin-top: 40px;
  margin-bottom: 40px;
}

.copyright {
  padding-top: 24px;
  padding-bottom: 48px;
}

.mobile_menu_link {
  text-align: center;
  margin: 0 0 25px;

  font-size: 28.8px;
  font-weight: 300;
  line-height: 1.2;
}

.mobile_nav {
  min-height: 0px;
  height: 0px;

  text-align: center;
  margin: 25px 10% 25px 10%;
  border-bottom: 0px solid #ddd;
  overflow: hidden;

  transition: min-height 0.5s;
  -webkit-transition: min-height 0.5s;
  -moz-transition: min-height 0.5s;
  -ms-transition: min-height 0.5s;
  -o-transition: min-height 0.5s;
}

.mobile_nav.inactive {
  border-bottom: 0px solid #ddd;
  min-height: 0px;
}

.mobile_nav.active {
  border-bottom: 1px solid #ddd;
  min-height: 120px;
}

.thumbnail {
  object-fit: cover;
  width: 100%;
  /* aspect-ratio: auto; */
  height: 100%;

  filter: opacity(0.9);

  transition: filter 0.2s ease-in-out;
  -webkit-transition: filter 0.2s ease-in-out;
  -moz-transition: filter 0.2s ease-in-out;
  -ms-transition: filter 0.2s ease-in-out;
  -o-transition: filter 0.2s ease-in-out;

  cursor: pointer;
}

.thumbnail_title {
  background-color: rgba(0, 0, 0, 0);
  padding: 8px 19.2px 11.2px 24px;
  margin: 0px;
  color: rgba(255, 255, 255, 1);
  align-content: flex-start;
  display: flex;
  position: relative;
  left: 0px;
  right: 0px;
  z-index: 9;

  opacity: 0;

  bottom: 76px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;

  transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
}

.thumbnail:hover {
  filter: opacity(0.4);
}

.thumbnail:hover ~ .thumbnail_title {
  opacity: 1;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
