.avatar {
  width: 100%;
  aspect-ratio: 1;
  height: auto;
}

.avatar_container {
  min-width: 20%;
  width: 20%;
  margin-left: 48px;
}

.about_container {
  margin-top: 48px;
  width: 100%;
}
